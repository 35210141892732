import { Component, Input, OnInit, Inject } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { AnalyticsService } from '../../../@core/utils';
import { NbAuthJWTToken, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { getDeepFromObject } from '../helpers';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';
  name = 'User';
  userMenu = [{ title: 'Log out' }];
  // userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  redirectDelay: number = 0;
  strategy: string = '';

  constructor(private authService: NbAuthService, private sidebarService: NbSidebarService, private router: Router,
    private menuService: NbMenuService, private analyticsService: AnalyticsService, private nbMenuService: NbMenuService,
    @Inject(NB_AUTH_OPTIONS) protected options = {}) {

      this.name = localStorage.getItem('name');

      this.redirectDelay = this.getConfigValue('forms.logout.redirectDelay');
      this.strategy = this.getConfigValue('forms.logout.strategy');
  }

  ngOnInit() {
    // this.userService.getUsers()
    //   .subscribe((users: any) => this.user = users.admin);
    this.nbMenuService.onItemClick()
    .pipe(
      filter(({ tag }) => tag === 'my-context-menu'),
      map(({ item: { title } }) => title),
    )
    .subscribe((title) => {
      if (title = 'Log out') {
        this.logout();
      }
    });

  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  logout() {
    localStorage.removeItem('name');
    localStorage.removeItem('user_id');
    localStorage.removeItem('companyid');
    this.authService.logout(this.strategy)
      .pipe(
        delay(this.redirectDelay),
      )
      .subscribe(() => this.router.navigate(['/auth/login']));
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
